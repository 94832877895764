import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Header from '../common_components/Header';
import Faq from './Faq';
import Profile from './Profile';
import Register from './Register';
import Regulasi from './Regulasi';
import Statistik from './Statistik';
import ProsedurKeberatan from './layanan_informasi/ProsedurKeberatan';
import ProsedurPengajuan from './layanan_informasi/ProsedurPengajuan';
import api_route from '../common_components/api_route';
import {CryptoJSAesDecrypt, getAllUrlParams} from '../common_components/helper';
import Survey from './Survey';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Home extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            isVerified: false,
            data_slider: null,
            data_regulasi: null,
            hash: getAllUrlParams(window.location.href).hash
		}

        this.jumpTo = this.jumpTo.bind(this);
    }

    componentDidMount(){
        
		$.ajax({
            url: api_route.slider,
            type: 'GET',
            success: (response) => {
			  this.setState({
                data_slider : response.data
              })
            },
            error: (err, response) => {
              alert('connection error');
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
        
        if(this.state.hash != undefined && this.state.hash != null){
            this.jumpTo(this.state.hash)
        }

        if(localStorage.getItem('key') != null){
            this.setState({
                isVerified: true
            })
        }
    }

    jumpTo(section) {
        const el = document.querySelector("#"+section);
        const y = el.getBoundingClientRect().top + window.pageYOffset - 75;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    handleClick = (e) => {
        // e.preventDefault();
        let data_slider = this.state.data_slider;
        data_slider.map((value, index) => {    
            // e.currentTarget 
            // location.href = value.url;
            // console.log(value.url);
        });        
      };

    render() {
        const resources = {
            emojis: [
              {
                id: 0,
                name: 'Kurang Puas',
                imageUrl: 'https://assets.ccbp.in/frontend/react-js/sad-emoji-img.png',
              },
              {
                id: 1,
                name: 'Puas',
                imageUrl: 'https://assets.ccbp.in/frontend/react-js/none-emoji-img.png',
              },
              {
                id: 2,
                name: 'Sangat Puas',
                imageUrl: 'https://assets.ccbp.in/frontend/react-js/happy-emoji-img.png',
              },
            ],
            loveEmojiUrl: 'https://assets.ccbp.in/frontend/react-js/love-emoji-img.png',
          }

        let data_slider = this.state.data_slider;
        let slider_list = [];
        let indicator_list = [];

		if(data_slider != null && data_slider.length > 0){
			data_slider.map((value, index) => {                
                slider_list.push(
                    <div className={index == 0 ? "carousel-item active" : 'carousel-item '}  style={{backgroundImage: 'url("'+api_route.slider_path+value.file+'")'}} onClick={()=>{value.url == null ? window.location.href = '#' : window.location.href = value.url;}}>
                        <div className="carousel-container">
                        </div>
                    {/* <div className={index == 0 ? "carousel-item active" : 'carousel-item '} style={{backgroundImage: 'url('+api_route.slider_path+value.file+')', backgroundSize: 'cover', height:'100vh'}} key={index}>
                        <div className="carousel-caption">
                        <h2 className="display-4" style={{textShadow: '2px 2px 4px #000000'}}>Ease of Mind</h2>
                        <p className="lead">Asuransi jiwa tradisional yang memberikan perlindungan atas risiko meninggal dunia pada Tertanggung dengan Premi terjangkau.</p>
                        <a href="https://pan.tokiomarine-life.co.id:8243/media/pdf/worksite%20brosur%20set%20ease%20of%20mind.pdf" target="_blank"><button type="button" className="btn btn-default">Unduh Brosur</button></a>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#mind-modal">Simulasi Premi</button>
                        </div>
                    </div> */}
                    </div>
                );
                indicator_list.push(
                    <li data-target="#carouselExampleIndicators" data-slide-to={index} className={index == 0 ? "active" : ''} key={index}></li>
                );
            });
		}
        return (
            <div className="App">
                <Header role="2" />
                <section id="hero">
                    <div className="hero-container">
                        <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">
                            <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>
                            <div className="carousel-inner" role="listbox" >                            
                                {slider_list}
                            </div>
                            <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                                <span className="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </section>
                <Profile />
                <Regulasi />
                {/* <ProsedurPengajuan />
                <ProsedurKeberatan /> */}
                <Faq />
                {/* <Statistik /> */}
                {/* { this.state && this.state.isVerified == true ? <Survey resources={resources}/> : ''} */}
                <Survey resources={resources} isVerified={this.state.isVerified}/>
            </div>
        );
    }
}
export default Home;
